import HttpUtil from '@/utils/HttpUtil';


// 获取角色列表
export const getRoleList = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/query/list';
  return HttpUtil.post(url, params);
};

// 获取角色列表
export const getRolePage = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/query/page';
  return HttpUtil.post(url, params);
};

// 新增角色
export const createRole = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/create';
  return HttpUtil.post(url, params);
};

// 修改角色
export const updateRole = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/update';
  return HttpUtil.post(url, params);
};

// 删除角色
export const deleteRole = (roleId: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/delete';
  return HttpUtil.get(url, { roleId });
};

// 发布角色
export const publishedRole = (roleId: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/published';
  return HttpUtil.get(url, { roleId });
};

// 角色详情
export const detailRole = (roleId: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/details';
  return HttpUtil.get(url, { roleId });
};

// 角色绑定菜单
export const setRoleMenus = (id: string, menus: string[]) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/setRoleMenus';
  return HttpUtil.post(url, { id, menus });
};

// 角色获取绑定的菜单
export const getRoleMenus = (roleId: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/getRoleMenus';
  return HttpUtil.get(url, { roleId });
};

// 角色绑定权限
export const setRolePermissions = (id: string, permissions: string[]) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/setRolePermissions';
  return HttpUtil.post(url, { id, permissions });
};

// 角色获取绑定的权限
export const getRolePermissions = (roleId: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/getRolePermissions';
  return HttpUtil.get(url, { roleId });
};

// 角色获取绑定身份范围
export const getIdentityScope = (roleId: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + 'admin/role/getIdentityScope';
  return HttpUtil.get(url, { roleId });
};
