var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.getTitle(),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.roleData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_name"), prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.roleData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.roleData, "name", $$v)
                          },
                          expression: "roleData.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_code"), prop: "code" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.roleData.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.roleData, "code", $$v)
                          },
                          expression: "roleData.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_sort"), prop: "sort" } },
                    [
                      _c("el-input-number", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.roleData.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.roleData, "sort", $$v)
                          },
                          expression: "roleData.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_scope_of_identity"),
                        prop: "identityScope",
                      },
                    },
                    [
                      !_vm.showIdentityScope
                        ? _c("div", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: true,
                                expression: "true",
                              },
                            ],
                            staticStyle: { width: "100%", height: "600px" },
                          })
                        : _vm._e(),
                      _vm.showIdentityScope
                        ? _c("bind-scope", {
                            attrs: {
                              tenantId: _vm.tenant ? _vm.tenant.id : "",
                              "select-scope-list": _vm.selectedScopeList,
                            },
                            on: { done: _vm.updateSelectDeptUser },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveForm } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }