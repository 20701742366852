/**
 * 角色实体
 */
export interface RoleModel {
  id?: string;
  tenantId: string;
  code: string;
  name: string;
  sort: number;
  identityScope: IdentityScopeMode;
  published: boolean;
}

/**
 * 身份范围实体
 */
export interface IdentityScopeMode {
  userIds?: string[];
  groupIds?: string[];
}

export const RoleData: RoleModel = {
  id: '',
  code: '',
  name: '',
  sort: 0,
  identityScope: {
    userIds: [],
    groupIds: []
  },
  published: false,
  tenantId: ''
};

// 角色选择的部门、用户实体
export interface RoleSelectDeptUserModel {
  id?: string;
  type: string;
  name: string;
}
