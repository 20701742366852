



















































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { RoleData, RoleModel, RoleSelectDeptUserModel } from '@/models/system/RoleModel';
import { createRole, detailRole, getIdentityScope, updateRole } from '@/api/system/RoleApi';
import { TenantModel } from '@/models/system/TenantModel';
import BindScope from '@/components/bind-scope/index.vue';

@Component({
  name: 'RoleForm',
  components: {
    BindScope
  }
})
export default class RoleForm extends Vue {
  // ref
  @Ref() readonly formRef;

  // prop
  // 弹窗显隐
  @Prop({ default: false }) visible!: boolean;
  // 角色id
  @Prop({ default: '' }) roleId!: string;
  // 租户id
  @Prop({ default: null }) tenant!: TenantModel;

  // 角色新增/编辑表单实体
  roleData: RoleModel = RoleData;
  // 表单加载状态
  formLoading: boolean = false;
  // 表单验证规则
  formRules: Record<string, any> = {
    name: {
      required: true,
      message: '请填写角色名称',
      trigger: 'blur'
    }
  };

  // 选择的部门、用户列表
  selectedScopeList: RoleSelectDeptUserModel[] = [];

  // 是否显示身份范围组件
  showIdentityScope: boolean = false;

  /**
   * 接收绑定范围组件传过来的值
   * @param val
   */
  updateSelectDeptUser(val) {
    this.selectedScopeList = val;
  }

  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {
      // 如果有传入角色id则查询详情
      if (this.roleId) {
        this.formLoading = true;
        detailRole(this.roleId)
          .then((res: Record<any, any>) => {
            if (res.code == '1') {
              this.roleData = res.data;

              // 获取角色绑定的身份范围
              this.getIdentityScopeData();
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {
            this.formLoading = false;
          });
      } else {
        // 显示身份范围组件组件
        this.showIdentityScope = true;
      }
    } else {
      this.selectedScopeList = [];
      this.showIdentityScope = false;
      // 清空表单内容
      this.roleData = RoleData;
      // 清理表单验证
      this.formRef.resetFields();
    }
  }

  /**
   * 获取角色绑定的身份范围
   */
  getIdentityScopeData() {
    this.formLoading = true;
    // 获取角色绑定的身份范围
    getIdentityScope(this.roleId)
      .then((res: Record<any, any>) => {
        let data = res.data;
        if (res.code === '1' && data) {
          let groupList = data.groupList;
          let userList = data.userList;
          if (groupList) {
            groupList.forEach(item => {
              let row: RoleSelectDeptUserModel = {
                id: item.id,
                type: 'org',
                name: item.name
              };
              this.selectedScopeList.push(row);
            });
          }

          if (userList) {
            userList.forEach(item => {
              let row: RoleSelectDeptUserModel = {
                id: item.id,
                type: 'user',
                name: item.name
              };
              this.selectedScopeList.push(row);
            });
          }
          // 显示身份范围组件
          this.showIdentityScope = true;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.formLoading = false;
      });
  }

  getTitle(): string {
    let title: string = '';
    if (this.tenant && this.tenant.name) {
      title += this.tenant.name + ' ';
    }
    title += this.roleId ? this.$t('lang_edit_role') : this.$t('lang_new_role');
    return title;
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 保存表单
   */
  saveForm() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      // 构建请求数据组合身份范围参数
      let userIds: any[] = [];
      let groupIds: any[] = [];
      this.selectedScopeList.forEach(item => {
        if (item.type === 'user') {
          userIds.push(item.id);
        } else if (item.type === 'org') {
          groupIds.push(item.id);
        }
      });
      this.roleData.identityScope = { userIds, groupIds };

      let result;
      if (this.tenant) {
        this.roleData.tenantId = this.tenant.id;
      }
      if (this.roleId) {
        result = updateRole(this.roleData);
      } else {
        result = createRole(this.roleData);
      }

      result
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success', res.data);
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
